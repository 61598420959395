import React from 'react';
import * as SITES from '../../constants/sites';

const Header = React.forwardRef((props, ref) => {
	const site = window.location.host === "training.preludemusicplanner.org" ? SITES.PRE : SITES.SS;

	return (
		<header ref={ref}>
			<div className="logo">
				<a className={site.className} href={site.href}>{site.text}</a> 
			</div>
		</header>
	);
});

export default Header;