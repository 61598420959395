import ssLogo from '../images/logo_sm_white-ss.svg';
import preLogo from '../images/logo_sm_white-pre.svg';

export const SS = {
	text: "Sundays and Seasons",
	className: "ss",
	href: "https://www.sundaysandseasons.com",
	logo: ssLogo
};

export const PRE = {
	text: "Prelude Music Planner",
	className: "pre",
	href: "https://www.preludemusicplanner.org",
	logo: preLogo
};