export const WFW = [
	{
		title: 'Words For Worship Tutorial',
		description: `
			<p>Moving from the Word for Worship CD-ROM to SundaysandSeasons.com? Watch this video to learn more.</p>
		`,
		url: 'https://vimeo.com/1010349987',
		img: 'https://i.vimeocdn.com/video/1927714365-5f289f08c1e6f75f7227689c7fd0545f8081a291c743676569ac464622e78cd6-d'
	}
];

export const HOME_PAGE = [
	{
		title: 'Home Page Overview - Part 1',
		description: `
			<p>In this video viewers will...</p>
			<ul>
				<li>Walk through key features of the Sundays and Seasons home page.</li>
				<li>Learn how to navigate the Sundays and Seasons banner calendar.</li>
				<li>See how to make a custom calendar for your worship team.</li>
			</ul>
		`,
		url: 'https://vimeo.com/661256463',
		img: 'https://i.vimeocdn.com/video/1339225872-fa16057ff93c035b498067a0e2c59dc4223e1d4f1f890a6c0cad0b6e910f3bca-d'
	},
	{
		title: 'Home Page Overview - Part 2',
		description: `
			<p>In this video viewers will...</p>
			<ul>
				<li>Review content in Day Texts and Day Resources.</li>
				<li>Scroll through some Subscriber News.</li>
				<li>See the importance of the User Content area.</li>
				<li>Work with some Custom Texts.</li>
			</ul>
		`,
		url: 'https://vimeo.com/661256665',
		img: 'https://i.vimeocdn.com/video/1336938689-38cefd2738e471e6de7b8164e818a7fec171469921f94b90038e01f2cb4242fd-d'
	}
];

export const TOURING_TABS = [
	{
		title: 'Touring the Tabs - Part 1',
		description: `
			<p>In this video viewers will...</p>
			<ul>
				<li>Explore the Library, Music, and Visuals tabs of sundaysandseasons.com.</li>
				<li>Learn about optional add-on functions for Preachers and Musicians.</li>
				<li>Take note of some powerful tools in the online Bible.</li>
			</ul>
		`,
		url: 'https://vimeo.com/661255348',
		img: 'https://i.vimeocdn.com/video/1336935872-e3cd5c916b2a5aa85f4287001a9259b21ce2fd86ec0deae2fae9a4213efaac88-d'
	},
	{
		title: 'Touring the Tabs - Part 2',
		description: `
			<p>In this video viewers will...</p>
			<ul>
				<li>Explore the My Account and Helps tabs of sundaysandseasons.com.</li>
				<li>Set-up the platform for local use and optimal efficiency.</li>
				<li>Learn how to add new users.</li>
				<li>Learn how to find support when needed.</li>
			</ul>
		`,
		url: 'https://vimeo.com/661958892',
		img: 'https://i.vimeocdn.com/video/1339242780-0e2677f25d0bbf6670e1b89471001c41c509baf969ca745681dea99c4c702466-d'
	}
];

export const USING_PLANNER = [
	{
		title: 'Using the Planner - Part 1',
		description: `
			<p>In this video viewers will...</p>
			<ul>
				<li>Receive &ldquo;quick start&rdquo; instructions for the basic use of the Planner.</li>
				<li>Walk through the three-part planning process.</li>
				<li>Preview and Download a basic plan.</li>
			</ul>
		`,
		url: 'https://vimeo.com/661255669',
		img: 'https://i.vimeocdn.com/video/1336937057-0090e08e79c7e9873262fd4c74e1642f5d0c59123dbc73b870cfbca82c521dae-d'
	},
	{
		title: 'Using the Planner - Part 2',
		description: `
			<p>In this video viewers will...</p>
			<ul>
				<li>Find and edit a template</li>
				<li>Use the edited template to start a new worship plan.</li>
				<li>Create a highly edited plan.</li>
			</ul>
		`,
		url: 'https://vimeo.com/661256141',
		img: 'https://i.vimeocdn.com/video/1336937693-88bf190e5868368e00fbc1e402dcec08460a7ccb0b644a7a73b3333a8eb2d9c7-d'
	},
	{
		title: 'Using the Planner - Part 3',
		description: `
			<p>In this video viewers will...</p>
			<ul>
				<li>Review the various sources of worship content that are available to subscribers of sundaysandseasons.com.</li>
				<li>Learn more details about the very useful &ldquo;Insert&rdquo; and &ldquo;Replace&rdquo; actions in the planner.</li>
				<li>Add alternative content to a base liturgy.</li>
			</ul>
		`,
		url: 'https://vimeo.com/661960636',
		img: 'https://i.vimeocdn.com/video/1339245754-63c79960348955365c9112916186be153e8f06f169a51b958566c02e61465869-d'
	}
];

export const QUICK_TIPS = [
	{
		title: 'Icon Quick Tips - Site Content Icons',
		description: `
			<p>In this video viewers will see how the Sundays and Seasons content icons point to a vast sets of uploaded worship content.</p>
		`,
		url: 'https://vimeo.com/669804429',
		img: 'https://i.vimeocdn.com/video/1356851787-c5e42d4c8c9cb13c37160d34c1b460d0b9d9f14c7c9e38ce426cea5d0fb5e895-d'
	},
	{
		title: 'Icon Quick Tips - Local Content Icons',
		description: `
			<p>In this video viewers will see how the local content icons can both personalize and  extend your use of sundaysandseasons.com.</p>
		`,
		url: 'https://vimeo.com/664807430',
		img: 'https://i.vimeocdn.com/video/1345572412-4212a213fc6e9d8f352b94453a0d97a45fce4a305038a6f648198b29deff0fe0-d'
	},
	{
		title: 'Icon Quick Tips - Planner Icons',
		description: `
			<p>In this video viewers will see how the Sundays and Seasons Planner icons motivate the full use of the online worship planner.</p>
		`,
		url: 'https://vimeo.com/669799984',
		img: 'https://i.vimeocdn.com/video/1356842548-b4bf99abd1df933ccf0292e1ab8bb0b6b90a258c850c3754ce2f0ea299039dc0-d'
	},
	{
		title: 'Icon Quick Tips - Preview Icon',
		description: `
			<p>In this video viewers will...</p>
			<ul>
				<li>See the various places the "Preview" icon appears in sundaysandseasons.com</li>
				<li>Discover the unique value and use of this action in each case.</li>
			</ul>
		`,
		url: 'https://vimeo.com/662734317',
		img: 'https://i.vimeocdn.com/video/1341002552-9a977feb058e8a0696c613ea4cbe89e4684500a7fb85ab0c72a132738e3beea9-d'
	},
	{
		title: 'Icon Quick Tips - Copy to Clipboard Icon',
		description: `
			<p>In this video viewers will...</p>
			<ul>
				<li>See the various places the &ldquo;Copy to Clipboard&rdquo; icon appears in sundaysandseasons.com</li>
				<li>Discover the unique value and use of this action in each case.</li>
			</ul>
		`,
		url: 'https://vimeo.com/662987336',
		img: 'https://i.vimeocdn.com/video/1341595801-301d3cf49528270a2a0040295f1d07cc129b84c436313291766d68582572cf2d-d'
	},
	{
		title: 'Icon Quick Tips - Edit Icon',
		description: `
			<p>In this video viewers will...</p>
			<ul>
				<li>See the various places the &ldquo;Edit&rdquo; icon appears in sundaysandseasons.com</li>
				<li>Discover the unique value and use of these actions in each case.</li>
			</ul>
		`,
		url: 'https://vimeo.com/664749568',
		img: 'https://i.vimeocdn.com/video/1345473729-9ffb05a785b61a625efa6b357f2f9ce8fdec2d740886d95a9c57e9423a12e19b-d'
	}
];

export const PRELUDE = [
	{
		title: 'Prelude Tutorial: Part 1',
		description: `
			<p>This tutorial covers the &ldquo;Browse&rdquo; and &ldquo;Buy&rdquo; features of Prelude. It serves as an overview of the platform and provides &ldquo;quick start&rdquo; information about finding and purchasing instrumental and vocal music using the Prelude Music Planner.</p>
		`,
		url: 'https://vimeo.com/681894087',
		img: 'https://i.vimeocdn.com/video/1382131158-93c5e6fd1d5997745b7544e0d948ca114bf0767c1b187cdb700a182fb84587a4-d'
	},
	{
		title: 'Prelude Tutorial: Part 2',
		description: `
			<p>This tutorial covers the &ldquo;Build,&rdquo; &ldquo;Blend,&rdquo; and &ldquo;Share&rdquo; features of Prelude. It serves as a deeper dive into the platform&rsquo;s planning functions and provides an overview of the account settings that can optimize a subscriber's use of the Prelude Music Planner.</p>
		`,
		url: 'https://vimeo.com/681893922',
		img: 'https://i.vimeocdn.com/video/1382091316-c33189699b4e88892c6fc1f6a96d218f0387079e4d1e614d395b8628564474b0-d'
	}
];