import React, { Component } from 'react';
import ReactPlayer from 'react-player/vimeo';

import Library from '../Library';

class VideoPlayer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			url: '',
			playing: false
		}
		this.videoPlayerRef = React.createRef();
	}

	handleTileClick = (event) => {
		const target = event.target;
		const div = target.closest('.tile');
		const url = div.getAttribute('data-url');
		const activeTile = document.querySelector('.tile.active');
		const player = this.videoPlayerRef.current;

		if (!div.classList.contains('active')) {
			if (activeTile) {
				activeTile.classList.remove('active');
			}
			
			div.classList.add('active');
		}

		if (!player.classList.contains('fixed')) {
			player.classList.add('fixed');
		}

		this.setState({
			url: url,
			playing: true
		});
	}

	handleCloseClick = () => {
		const player = this.videoPlayerRef.current;
		const activeTile = document.querySelector('.tile.active');

		player.classList.remove('fixed');

		if (activeTile) {
			activeTile.classList.remove('active');
		}

		this.setState({
			url: '',
			playing: false
		});
	}

	render () {
		const { url, playing } = this.state;

		return (
			<>
				<section className="video-player" ref={this.videoPlayerRef}>
					<div className="wrapper">
						<div className="player-wrapper">
							<div className="player-container">
								<button className="close" title="Close" onClick={this.handleCloseClick} />
								<ReactPlayer
									className="react-player"
									width="100%"
									height="100%"
									url={url}
									playing={playing}
									controls={true}
									config={{
										vimeo: {
											playerOptions: {
												playsinline: true,
												color: "007FB5"
											}
										}
									}}
								/>
							</div>
						</div>
					</div>
				</section>

				<Library
					handleTileClick={this.handleTileClick}
					url={this.state.url}
				/>
			</>
		);
	}
};

export default VideoPlayer;