import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import Header from '../Header';
import VideoPlayer from '../VideoPlayer';
import Footer from '../Footer';

class HomePage extends Component {
	constructor(props) {
		super(props);
		this.scrollTopRef = React.createRef();
		this.headerRef = React.createRef();
	}

	mainVisibilityChange = (isVisible) => {
		const scrollTop = this.scrollTopRef.current;

		if (!isVisible) {
			scrollTop.classList.add('visible');
		} else {
			if (scrollTop.classList.contains('visible')) {
				scrollTop.classList.remove('visible');
			}
		}
	}

	footerVisibilityChange = (isVisible) => {
		const scrollTop = this.scrollTopRef.current;

		if (isVisible) {
			scrollTop.classList.add('unfixed');
		} else {
			if (scrollTop.classList.contains('unfixed')) {
				scrollTop.classList.remove('unfixed');
			}
		}
	}

	scrollToTop = () => {
		const header = this.headerRef.current;
		header.scrollIntoView({ behavior: 'smooth' });
	}

	render () {
		return (
			<VisibilitySensor
				onChange={this.mainVisibilityChange}
				partialVisibility='top'
			>
				<>
					<Header ref={this.headerRef} />
					<main>
						<section>
							<div className="wrapper">
								<div className="feature feature--center">
									<h1>Welcome to the <span className="no-wrap">Sundays and Seasons</span> OnDemand Training Library</h1>
									<p style={{ 'marginBottom': 0 }}>This library contains video tutorials that support the use of <a href="https://www.sundaysandseasons.com">sundaysandseasons.com</a> and <a href="https://www.preludemusicplanner.org">preludemusicplanner.org</a>. We want to help you get the most out of your subscriptions to these content rich and highly functional online platforms that support your congregational worship and music planning. The four playlists each support key aspects of this robust application.</p>
								</div>
							</div>
						</section>

						<VideoPlayer />

						<button className="scrollTop" ref={this.scrollTopRef} onClick={this.scrollToTop}>Back to Top</button>
					</main>

					<VisibilitySensor
						onChange={this.footerVisibilityChange}
						partialVisibility={true}
						offset={{ top:10 }}
					>
						<Footer />
					</VisibilitySensor>
				</>
			</VisibilitySensor>
		);
	}
};

export default HomePage;