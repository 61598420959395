import React from 'react';

const Footer = () => (
	<footer>
		<nav>
			<a href="https://www.sundaysandseasons.com">Sundays and Seasons</a>
			<a href="http://help.sundaysandseasons.com">Sundays and Seasons Help</a>
			<a href="https://www.preludemusicplanner.org">Prelude Music Planner</a>
			<a href="https://preludehelpsite.azurewebsites.net">Prelude Music Planner Help</a>
		</nav>
	</footer>
);

export default Footer;